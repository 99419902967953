export default class Utilities {
    static getKey() {
        const params = (new URL(document.location.toString())).searchParams;
        const key = params.get("key") || "";
        return key;
    }

    static getChannel() {
        const params = (new URL(document.location.toString())).searchParams;
        const channel = params.get("channel") ||
            params.get("channelId") ||
            params.get("channelID") ||
            "97032862";
        return channel;
    }
}